@layer modules, ui, base;
@layer ui {
  /** @define Text */

.text_jds-Text__15y8h {
  color: inherit;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.text_jds-Text--normal__VuON_,
.text_jds-Text--edito__aBPHN {
  font-size: var(--body1);
  line-height: var(--body1-lh);
}

.text_jds-Text--small__cqvDw,
.text_jds-Text--label__NchAR {
  font-size: var(--body2);
  line-height: var(--body2-lh);
}

.text_jds-Text--label__NchAR {
  font-weight: var(--semiBold);
  text-transform: uppercase;
}

.text_jds-Text--minor__jABjP,
.text_jds-Text--caption__6nX_h {
  font-size: var(--caption1);
  line-height: var(--caption-lh);
}

.text_jds-Text--heading-extra___Yr_d,
.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH,
.text_jds-Text--heading__xKbad,
.text_jds-Text--heading-small__0_01a {
  font-family: var(--fontHeading);
  font-weight: var(--bold);
}

.text_jds-Text--heading-extra___Yr_d {
  font-size: var(--display1);
  line-height: var(--display1-lh);
}

.text_jds-Text--headingBig__eT9R6,
.text_jds-Text--heading-big__oPKWH {
  font-size: var(--display2);
  line-height: var(--display2-lh);
}

.text_jds-Text--heading__xKbad {
  font-size: var(--title2);
  line-height: var(--title2-lh);
}

.text_jds-Text--heading-small__0_01a {
  font-size: var(--title3);
  line-height: var(--title3-lh);
}

.text_jds-Text--subhead___TgRV,
.text_jds-Text--subhead-small__5bkWB {
  font-size: var(--title4);
  line-height: var(--title4-lh);
}

.text_jds-Text--caps__D8GoJ {
  text-transform: uppercase;
}

.text_jds-Text--italic__GIuFn {
  font-style: italic;
}

.text_jds-Text--uppercase__hEvTJ {
  text-transform: uppercase;
}

.text_jds-Text--resetSpacing__gNair {
  margin: 0;
  padding: 0;
}

.text_jds-Text--left__IicTW {
  text-align: left;
}

.text_jds-Text--center__d0pko {
  text-align: center;
}

.text_jds-Text--right__hmj0F {
  text-align: right;
}

.text_jds-Text--black__jQhPm {
  color: var(--color-black);
}

.text_jds-Text--white__PkoKT {
  color: var(--color-white);
}

.text_jds-Text--grey__aKOUp {
  color: var(--color-grey--600);
}

.text_jds-Text--greyLight__gZE4a {
  color: var(--color-grey--200);
}

.text_jds-Text--mainColor__A2FdC {
  color: var(--color-black);
}

.text_jds-Text--secondColor__ngyVn {
  color: var(--color-blue);
}

.text_jds-Text--success__l9EGG {
  color: var(--color-success--500);
}

.text_jds-Text--warning__UGG2C {
  color: var(--color-warning--500);
}

.text_jds-Text--error__rkigS {
  color: var(--color-error--500);
}

.text_jds-Text--weight-light__Ob2Dc {
  font-weight: var(--light);
}

.text_jds-Text--weight-normal__uo6Vk {
  font-weight: var(--normal);
}

.text_jds-Text--weight-medium__KxpPm {
  font-weight: var(--medium);
}

.text_jds-Text--weight-semi-bold__eF41S {
  font-weight: var(--semiBold);
}

.text_jds-Text--weight-bold__KNxoa {
  font-weight: var(--bold);
}

.text_jds-Text--weight-extra-bold__o56Nu {
  font-weight: var(--extraBold);
}

.text_jds-Text--weight-black__AYmOH {
  font-weight: var(--black);
}

@media (min-width: 48rem) {
  .text_jds-Text--heading__xKbad {
    font-size: var(--title1);
    line-height: var(--title1-lh);
  }

  .text_jds-Text--heading-small__0_01a {
    font-size: var(--title2);
    line-height: var(--title2-lh);
  }

  .text_jds-Text--subhead___TgRV,
  .text_jds-Text--subhead-small__5bkWB {
    font-size: var(--title3);
    line-height: var(--title3-lh);
  }
}

}
@layer ui {
  /** @define Spinner */

.Spinner_jds-Spinner__qSvCy {
  width: 1.25rem;
  height: 1.25rem;
  animation: Spinner_spinner__z_cy6 2s linear infinite;
  stroke-width: 0.6rem;
  color: currentcolor;
  vertical-align: middle;
}

.Spinner_jds-Spinner--minor__v4Zv_ {
  width: 1rem;
  height: 1rem;
}

.Spinner_jds-Spinner--ghost__g_jYG {
  color: var(--color-black);
}

@keyframes Spinner_spinner__z_cy6 {
  50% {
    transform: rotate(360deg);
    stroke-dashoffset: 20;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 243.4675;
  }
}

}
@layer ui {
  /** @define Icon */

.Icon_jds-Icon__qGb6e {
  fill: currentcolor;
  vertical-align: middle;
}

.Icon_jds-Icon--tiny__eJMHC {
  width: var(--icon-size-tiny);
  min-width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
}

.Icon_jds-Icon--small__TQ5up {
  width: var(--icon-size-small);
  min-width: var(--icon-size-small);
  height: var(--icon-size-small);
}

.Icon_jds-Icon--medium__CgyeE {
  width: var(--icon-size-medium);
  min-width: var(--icon-size-medium);
  height: var(--icon-size-medium);
}

.Icon_jds-Icon--big__bzeG_ {
  width: var(--icon-size-big);
  min-width: var(--icon-size-big);
  height: var(--icon-size-big);
}

.Icon_jds-Icon--large__KNPQa {
  width: var(--icon-size-large);
  min-width: var(--icon-size-large);
  height: var(--icon-size-large);
}

.Icon_jds-Icon--huge__GF_BE {
  width: var(--icon-size-huge);
  min-width: var(--icon-size-huge);
  height: var(--icon-size-huge);
}

}
@layer ui {
  /** @define Image */

/* Legacy variables used in other repositories */
/* stylelint-disable plugin/selector-bem-pattern */

.image_jds-Image__MmccF {
  --image-box-tiny: 24px;
  --image-box-small: 40px;
  --image-box-medium: 64px;
  --image-box-big: 80px;
  --image-box-large: 128px;
}

.image_jds-Image__box--tiny__1MfO2 {
  width: var(--image-box-tiny);
  height: var(--image-box-tiny);
}

.image_jds-Image__box--small__9_Eyw {
  width: var(--image-box-small);
  height: var(--image-box-small);
}

.image_jds-Image__box--medium__ykp0v {
  width: var(--image-box-medium);
  height: var(--image-box-medium);
}

.image_jds-Image__box--big__b2oxK {
  width: var(--image-box-big);
  height: var(--image-box-big);
}

.image_jds-Image__box--large__zSe2O {
  width: var(--image-box-large);
  height: var(--image-box-large);
}

.image_jds-Image__box--circle__K3tNB {
  border-radius: var(--radius-900);
}

.image_jds-Image__box--undistorted__VcE43 {
  -o-object-fit: cover;
     object-fit: cover;
}

}
@layer ui {
  /** @define Button */

.button_jds-Button__Cbo3A {
  --Button-border-width: 2px;
  --Button-horizontaly-padding: calc(16px - var(--Button-border-width));
  --Button-verticaly-padding: calc(12px - var(--Button-border-width));
  --Button-minor-horizontaly-padding: calc(16px - var(--Button-border-width));
  --Button-minor-verticaly-padding: calc(10px - var(--Button-border-width));
  --Button-icon-only: 10px;
  --Button-icon-only-minor: 10px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: var(--Button-verticaly-padding) var(--Button-horizontaly-padding);
  border: var(--Button-border-width) solid var(--themeMainColor, var(--mainColor));
  border-radius: var(--radius-200);
  background-color: var(--themeMainColor, var(--mainColor));
  color: var(--themeButtonTextColor, var(--color-black));
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--semiBold);
  line-height: var(--normalBodyLineHeight);
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
}

.button_jds-Button__Cbo3A:focus:not(.button_jds-Button--onlyIcon__s_xZR) {
  outline: none;
}

.button_jds-Button__Cbo3A:hover {
  border-color: var(--themeMainDarkenColor, var(--color-green--550));
  background-color: var(--themeMainDarkenColor, var(--color-green--550));
}

.button_jds-Button__Cbo3A:active {
  border-color: var(--themeMainDarkenColor, var(--color-green--600));
  background-color: var(--themeMainDarkenColor, var(--color-green--600));
}

.button_jds-Button__Cbo3A:focus {
  border-width: var(--Button-border-width);
  border-color: var(--color-black);
  outline: none;
  background-color: var(--themeMainDarkenColor, var(--color-green--550));
}

/*******************
Secondary
*******************/
.button_jds-Button--ghost__lXkuc {
  border-color: var(--color-black);
  background-color: transparent;
  color: var(--color-black);
}

.button_jds-Button--ghost__lXkuc:hover { background-color: var(--color-grey--050); }
.button_jds-Button--ghost__lXkuc:focus { background-color: var(--color-grey--100); }
.button_jds-Button--ghost__lXkuc:active { background-color: var(--color-grey--200); }

.button_jds-Button--ghost__lXkuc:hover,
.button_jds-Button--ghost__lXkuc:focus,
.button_jds-Button--ghost__lXkuc:active { border-color: var(--color-black); }

.button_jds-Button--ghostInverted__wQ68N {
  border-color: var(--color-grey--800);
  background-color: var(--color-grey--800);
  color: var(--color-grey--025);
}

.button_jds-Button--ghostInverted__wQ68N:hover {
  background-color: var(--color-grey--500);
}

.button_jds-Button--ghostInverted__wQ68N:hover,
.button_jds-Button--ghostInverted__wQ68N:focus {
  border-color: var(--color-grey--900);
  outline: none;
  background-color: var(--color-grey--900);
}

.button_jds-Button--ghostInverted__wQ68N:active {
  border-color: var(--color-grey--950);
  background-color: var(--color-grey--950);
}

/************************
  Tertiary
*************************/
.button_jds-Button--naked__wn4BF {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-grey--900);
}

.button_jds-Button--naked__wn4BF:hover {
  border-color: transparent;
  background-color: var(--color-grey--100);
}

.button_jds-Button--naked__wn4BF:active {
  border-color: transparent;
  background-color: var(--color-grey--200);
}

.button_jds-Button--naked__wn4BF:focus {
  border-color: var(--color-grey--800);
  background-color: var(--color-grey--100);
}

/*******************
Status
*******************/
.button_jds-Button--error__y8_pk {
  border-color: var(--color-error--500);
  background-color: var(--color-error--500);
  color: var(--color-white);
}

.button_jds-Button--error__y8_pk:hover,
.button_jds-Button--error__y8_pk:focus {
  border-color: var(--color-error--550);
  background-color: var(--color-error--550);
}

.button_jds-Button--error__y8_pk:active {
  border-color: var(--color-error--600);
  background-color: var(--color-error--600);
}

.button_jds-Button--error__y8_pk:focus {
  border-color: var(--color-error--700);
}

.button_jds-Button--success__HiDUf {
  border-color: var(--color-success--500);
  background-color: var(--color-success--500);
  color: var(--color-white);
}

.button_jds-Button--success__HiDUf:hover,
.button_jds-Button--success__HiDUf:focus {
  border-color: var(--color-success--550);
  background-color: var(--color-success--550);
}

.button_jds-Button--success__HiDUf:active {
  border-color: var(--color-success--600);
  background-color: var(--color-success--600);
}

.button_jds-Button--success__HiDUf:focus {
  border-color: var(--color-black);
}

/************************************
  Loading Button / Animated Spinner
*************************************/

.button_jds-Button--loading__4ATJU {
  cursor: progress;
}

.button_jds-Button__spinnerHiddenContent__QRjEC {
  display: inline-flex;
  visibility: hidden;
  align-items: center;
}

.button_jds-Button__spinner__ESH_A {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.button_jds-Button--ghost__lXkuc.button_jds-Button--loading__4ATJU {
  border-color: var(--color-black);
  background-color: var(--color-grey--025);
  color: var(--color-black);
}

/**********************************
  Button with icon
***********************************/
.button_jds-Button--withIcon__m_JDp {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button_jds-Button__icon__ZHjCZ {
  box-sizing: content-box;
  flex-basis: auto;
  padding-right: var(--spacer-100);
}

.button_jds-Button--withIconOnRight__Hu8Cy {
  flex-direction: row-reverse;
}

.button_jds-Button--withIconOnRight__Hu8Cy .button_jds-Button__icon__ZHjCZ {
  padding: 0 0 0 var(--spacer-100);
}

/**********************************
  Button with only icon
***********************************/
.button_jds-Button--onlyIcon__s_xZR {
  display: inline-flex;
  padding: var(--Button-icon-only);
}

.button_jds-Button--onlyIcon__s_xZR .button_jds-Button__icon__ZHjCZ {
  padding: 0;
}

.button_jds-Button--onlyIcon__s_xZR:not(.button_jds-Button--minor__ukW_c) .button_jds-Button__icon__ZHjCZ {
  width: var(--icon-size-big);
  height: var(--icon-size-big);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.button_jds-Button--onlyIcon__s_xZR.button_jds-Button--minor__ukW_c {
  padding: var(--Button-icon-only-minor);
}

/**********************************
  Button disabled
***********************************/
.button_jds-Button--disabled___axeq,
.button_jds-Button--disabled___axeq:hover {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--200);
  color: var(--color-grey--400);
  cursor: not-allowed;
}

/* stylelint-disable plugin/selector-bem-pattern */
.button_jds-Button--ghost__lXkuc.button_jds-Button--disabled___axeq {
  border-color: var(--color-grey--300);
  background-color: var(--color-white);
  color: var(--color-grey--300);
}

.button_jds-Button--ghost__lXkuc.button_jds-Button--disabled___axeq:hover {
  background-color: transparent;
}

.button_jds-Button--disabled___axeq.button_jds-Button--ghostInverted__wQ68N,
.button_jds-Button--disabled___axeq.button_jds-Button--ghostInverted__wQ68N:hover {
  border-color: var(--color-grey--050);
  background-color: var(--color-grey--050);
  color: var(--color-grey--300);
}

.button_jds-Button--naked__wn4BF.button_jds-Button--disabled___axeq,
.button_jds-Button--naked__wn4BF.button_jds-Button--disabled___axeq:hover {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-grey--300);
}

.button_jds-Button--error__y8_pk.button_jds-Button--disabled___axeq {
  background-color: var(--color-error--100);
  color: var(--color-grey--200);
}

.button_jds-Button--success__HiDUf.button_jds-Button--disabled___axeq {
  background-color: var(--color-success--100);
  color: var(--color-grey--200);
}
/* stylelint-enable */

/******************************
  Loading and disabled Button
*******************************/

.button_jds-Button--loading__4ATJU[disabled] {
  cursor: wait;
}

/******************************
  Minor button
*******************************/
.button_jds-Button--minor__ukW_c {
  padding: var(--Button-minor-verticaly-padding) var(--Button-minor-horizontaly-padding);
  font-size: var(--body2);
  line-height: var(--body2-lh);
}

/******************************
  Full width button
*******************************/

.button_jds-Button--fullWidth__MTiwc {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  text-align: left;
}

}
@layer ui {
  /** @define Link */

.link_jds-Link__IVm1_ {
  color: var(--color-black);
  text-decoration: underline;
  text-underline-offset: 3px;
}

.link_jds-Link__IVm1_:hover {
  color: var(--color-black);
  text-underline-offset: 2px;
  -webkit-text-decoration: underline 2px;
          text-decoration: underline 2px;
}

.link_jds-Link__IVm1_:focus {
  outline: thin auto var(--color-grey--500);
  outline-offset: 4px;
  color: var(--color-black);
}

.link_jds-Link--button__gHSKU {
  cursor: pointer;
}

.link_jds-Link--minor__dX853 {
  font-size: var(--smallBody);
}

.link_jds-Link--line-height-edito__UWwuK {
  line-height: var(--editoBodyLineHeight);
}

.link_jds-Link--line-height-normal__zGWL1 {
  line-height: var(--normalBodyLineHeight);
}

.link_jds-Link--line-height-small__B_Djg {
  line-height: var(--smallBodyLineHeight);
}

.link_jds-Link--line-height-minor__5ahPx {
  line-height: var(--minorBodyLineHeight);
}

.link_jds-Link--reset__2EcM6,
.link_jds-Link--reset__2EcM6:hover,
.link_jds-Link--reset__2EcM6:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

}
@layer ui {
  /** @define Illustration */
.Illustration_jds-Illustration--big__I2Ypf {
  height: 320px;
}

.Illustration_jds-Illustration--small__QCVX_ {
  height: 200px;
}

}
@layer ui {
  /** @define Body */
/* stylelint-disable plugin/selector-bem-pattern */

.modal_jds-Body--withModal__qnH2p {
  overflow-y: hidden;
}

/** @define Modal */

.modal_jds-Modal__gZSZT {
  --Modal-padding: 1.25rem;
  display: flex;
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*
    Do not switch height to vh unit because behavior is inconsistent on mobile device
    See  https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile and
    https://nicolas-hoizey.com/2015/02/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers.html
    for more informations
  */
  height: 100%;
  overflow: hidden;
  animation: modal_show__fwtTO 0.2s ease-in forwards;
  opacity: 0;
}

.modal_jds-Modal__title__BOiYw {
  font-size: var(--title2);
  font-weight: var(--semiBold);
  line-height: var(--title2-lh);
}

/* Fading overlay */
.modal_jds-Modal__background__3lt8K {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: var(--color-black);
}

[class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
  --Modal-maxWidth: 35.25rem;

  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--Modal-padding);
  overflow-y: auto;
  background-color: var(--color-white);
  color: var(--color-black);
}

.modal_jds-Modal__wrapper--isCentered__b1_g_ {
  justify-content: center;
}

.modal_jds-Modal__wrapper--withIllustration__Oq7X2 {
  align-items: center;
  overflow-y: auto;
}

[class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
  padding: 0;
}

.modal_jds-Modal__header__ClF7_ {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.modal_jds-Modal__header--withIllustration__Ud_rK {
  align-items: center;
}

.modal_jds-Modal__header--withoutIcon__fzjpw {
  max-width: 90%;
}

[class*="jds-Modal"].modal_jds-Modal__header--stickyFooter__icmJv {
  padding-top: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

.modal_jds-Modal__closeButton__D01W9 {
  position: absolute;
  z-index: 1; /* in front of the Header */
  top: var(--Modal-padding);
  right: var(--Modal-padding);
  padding: 0;
  border: none;
  background: none;
  color: var(--color-black);
  cursor: pointer;
}

.modal_jds-Modal__footer__VnXGC {
  display: inline-flex;
  flex-flow: column-reverse wrap;
  justify-content: flex-end;
  gap: var(--spacer-200);
}

[class*="jds-Modal"].modal_jds-Modal__footer--sticky__UHRRb {
  position: sticky;
  bottom: 0;
  padding: calc(var(--Modal-padding) / 1.5);
  background-color: #ffffff;
  box-shadow: var(--minor-shadow);
}

.modal_jds-Modal__footer--withIllustration__MmxZl {
  align-items: center;
}

.modal_jds-Modal__body___hKfJ {
  position: relative; /* keep it to improve react-select behavior on modal. No clue, but it's better with it */
  padding: var(--spacer-300) 0;
}

.modal_jds-Modal__body--withIllustration__62YIo {
  height: auto;
  padding-top: var(--spacer-100);
  overflow-y: initial;
}

[class*="jds-Modal"].modal_jds-Modal__body--stickyFooter__uOW8j {
  padding-right: var(--Modal-padding);
  padding-left: var(--Modal-padding);
}

/* stylelint-disable-next-line -- force center alignment. */
.modal_jds-Modal__body--withIllustration__62YIo > * {
  align-items: center;
  text-align: center;
}

@media (min-width: 48rem) {
  [class*="jds-Modal"].modal_jds-Modal__wrapper__B4rkd {
    --Modal-padding: var(--spacer-400);
    max-width: var(--Modal-maxWidth);
    /* limit height on desktop to prevent the Modal from overflowing */
    height: auto;
    max-height: 90vh;
    padding: var(--Modal-padding);
    border-radius: var(--radius-400);
  }

  .modal_jds-Modal__footer__VnXGC {
    flex-direction: row;
  }

  [class*="jds-Modal"].modal_jds-Modal__wrapper--stickyFooter__kIoXz {
    padding: 0;
  }
}

@keyframes modal_show__fwtTO {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
@layer ui {
  .sk-Typography_main__I32O9 {
  margin: unset;
  padding: unset;
}

/**
 * Tokens seems wrong for font families and weights but they are not.
 * The naming constraint came from Token Studio in Figma part where tokens are
 * generated. Token Studio creates as many tokens as there are variants.
 * For example, if we have the following configuration:
 * 3 hierarchy variants
 * 3 weights variants
 * 2 font families
 * Token Studio will create: 3 * 3 * 2 = 18 tokens.
 * Instead, we can handle variants separately and have only 3 + 3 + 2 = 8 tokens.
 * In our case, all Text class names use:
 * - the same font family
 * - the same font weight according to weight variant
 *   (regardless of the font used: body1, body2 etc.).
 */

/**
 * Font families
 */

.sk-Typography_title1__kpKKc,
.sk-Typography_title2___x7oz,
.sk-Typography_display1__01oEX,
.sk-Typography_display2__CnKww {
  font-family: var(--sk-typography-title-1-font-family), helvetica, arial, sans-serif;
}

.sk-Typography_body1__rvFYv,
.sk-Typography_body2__W0CNH,
.sk-Typography_title3__c40PS,
.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-family: var(--sk-typography-body-1-regular-font-family), helvetica, arial, sans-serif;
}

/**
 * Weights
 */

.sk-Typography_regular__a_y2X {
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.sk-Typography_semiBold__OC7cU {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
}

.sk-Typography_bold__ICdus {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

/**
 * @FIXME: title token has been moved (.title1 uses --…-title-2) for mobile handling.
 *   See with @valentinmichel-jt for what needs to be done to make it more maintainable.
 *   N.B. .title4 is not handled yet and, in this case, it will miss the --…-title-5 token.
 *        We'll also have an n+1 missing token for each title variant added.
 */

.sk-Typography_title1__kpKKc {
  font-size: var(--sk-typography-title-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-2-line-height);
  text-decoration: none;
}

.sk-Typography_title2___x7oz {
  font-size: var(--sk-typography-title-3-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-3-line-height);
  text-decoration: none;
}

.sk-Typography_title3__c40PS {
  font-size: var(--sk-typography-title-4-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-4-line-height);
  text-decoration: none;
}

.sk-Typography_body1__rvFYv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-decoration: none;
}

.sk-Typography_body2__W0CNH {
  font-size: var(--sk-typography-body-2-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-decoration: none;
}

.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-size: var(--sk-typography-caption-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-caption-1-line-height);
  text-decoration: none;
}

.sk-Typography_caption1Underline__eZKaw {
  text-decoration: underline;
}

.sk-Typography_display1__01oEX {
  font-size: var(--sk-typography-display-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-1-line-height);
  text-decoration: none;
}

.sk-Typography_display2__CnKww {
  font-size: var(--sk-typography-display-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-2-line-height);
  text-decoration: none;
}

@media (min-width: 768px) {
  .sk-Typography_title1__kpKKc {
    font-size: var(--sk-typography-title-1-font-size);
    line-height: var(--sk-typography-title-1-line-height);
  }

  .sk-Typography_title2___x7oz {
    font-family: var(--sk-typography-title-2-font-family), helvetica, arial, sans-serif;
    font-size: var(--sk-typography-title-2-font-size);
    font-weight: var(--sk-typography-title-2-font-weight);
    line-height: var(--sk-typography-title-2-line-height);
  }

  .sk-Typography_title3__c40PS {
    font-size: var(--sk-typography-title-3-font-size);
    line-height: var(--sk-typography-title-3-line-height);
  }
}

}
